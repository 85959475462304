import { useState, useEffect } from "react";

import axios from '../../services/BackendService';
import PartnerItem from "../../components/partnerItem/PartnerItem";

import './partnerPage.scss';
import PartnerListItem from "../../components/partnerListItem/PartnerListItem";

const PartnerPage = () => {
     const [isLoading, setIsLoading] = useState(true);
     const [partners, setPartners] = useState([]);
     const [isGrid, setIsGrid] = useState(true);
     const [addedOrDeleted, setAddedOrDeleted] = useState(false);

     const showGrid = () => {
          setIsGrid(true);
     }

     const showList = () => {
          setIsGrid(false);
     }

     useEffect(() => {
          setAddedOrDeleted(false);
          axios.get('/partners')
               .then(res => setPartners(res.data))
               .catch(err => console.log(err))
               .finally(() => setIsLoading(false));
     }, [addedOrDeleted, partners]);
     return (
          <div className="partner">
               <div className="partner__header">
                    <h3 className="section__title">Партнеры</h3>
                    <div className="partner__btn-group">{
                         !isLoading && (
                              <>
                                   <div className={isGrid ?'partner__btn-item partner__btn-item-active' : 'partner__btn-item'}
                                        onClick={showGrid}>
                                        <i class="fa-solid fa-grip"></i>
                                   </div>
                                   <div className={isGrid ?'partner__btn-item' : 'partner__btn-item partner__btn-item-active'}
                                        onClick={showList}>
                                        <i class="fa-solid fa-list"></i>
                                   </div>
                              </>
                         )
                    }</div>
               </div>
               {
                    isLoading ? (
                         <h2>Загрузка...</h2>
                    ) : (
                         <>
                              <div className="partner__grid"
                                   style={{'display': isGrid ? 'grid' : 'none'}}>
                                   <PartnerItem
                                        key={1}
                                        setAddedOrDeleted={setAddedOrDeleted}
                                        id={"1"}
                                        image={null}
                                        ruTitle=""
                                        kzTitle=""
                                        enTitle=""
                                        partnerLink=""
                                        create={true}
                                        />
                                   {
                                        partners.map(obj => (
                                             <PartnerItem
                                                  key={obj._id}
                                                  setAddedOrDeleted={setAddedOrDeleted}
                                                  id={obj._id}
                                                  image={obj.imageUrl}
                                                  ruTitle={obj.ruTitle}
                                                  kzTitle={obj.kzTitle}
                                                  enTitle={obj.enTitle}
                                                  partnerLink={obj.link}
                                                  create={false}
                                                  />
                                        ))
                                   }
                              </div>
                              <div className="partner__list"
                                   style={{'display': isGrid ? 'none' : 'grid'}}>{
                                   partners.map(obj => (
                                        <PartnerListItem
                                             partnerId={obj._id}
                                             title={obj.ruTitle}
                                             hasIncrement={obj.orderNumber !== 1}
                                             hasDecrement={obj.orderNumber !== partners.length}
                                             />
                                   ))
                              }</div>
                         </>
                         
                    )
               }
          </div>
     )
}

export default PartnerPage;