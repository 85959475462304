import axios from '../../services/BackendService';

import './partnerListItem.scss';

const PartnerListItem = ({partnerId, title, hasIncrement, hasDecrement}) => {
     const incrementOrderOfPage = async (e, id) => {
          e.preventDefault();
          await axios.post(`/partner/increment/${id}`, null, {
               headers: {
                    Authorization: localStorage.getItem('token')
               }
          })
     }
     const decrementOrderOfPage = async (e, id) => {
          e.preventDefault();
          await axios.post(`/partner/decrement/${id}`, null, {
               headers: {
                    Authorization: localStorage.getItem('token')
               }
          })
     }
     return (
          <div className="partner__list-item">
               <div className="partner__list-item-part">
                    <h6>{title}</h6>
               </div>
               <div className="partner__list-item-part">
                    {
                         hasIncrement && (
                              <form 
                                   onSubmit={(e) => incrementOrderOfPage(e, partnerId)}>
                                   <button 
                                        type="submit"
                                        className="blog__item-links-item">
                                             <i class="fa-solid fa-arrow-up-long"></i>
                                             Вверх
                                   </button>
                              </form>
                         )
                    }
                    {
                         hasDecrement && (
                              <form 
                                   onSubmit={(e) => decrementOrderOfPage(e, partnerId)}>
                                   <button 
                                        type="submit"
                                        className="blog__item-links-item">
                                             <i class="fa-solid fa-arrow-down-long"></i>
                                             Вниз
                                   </button>
                              </form>
                         )
                    }
               </div>
          </div>
     )
}

export default PartnerListItem;